import React, { useContext, useState } from 'react';
import Press from '../../components/Press/Press';
import { HT_PAGE_CATEGORY_ARCHIVE_PAGE, MAX_NUMBER_OF_CARDS_PER_PAGE } from '../../constants';
import { graphql, PageProps } from 'gatsby';
import { getDefaultSEO } from '../../utils';
import Seo from '../../components/Seo';
import { IPressBlock } from '../../components/Press/components/PressBlock/PressBlock.def';
import HTPageWrapper from '../../wrappers/HTPageWrapper';
import { sendHtPageView } from '../../utils/helpers/htApi';
import HTEventsContext from '../../context/HTEventsContext';

interface IPressMentionsPageProps {
  pressList: Queries.WpUpOption;
}

const PressMentions = ({ data, location }: PageProps<IPressMentionsPageProps>) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const htevents = useContext(HTEventsContext);
  const title = 'Upgraded Points: Press and Brand Mentions Across the Web';
  const { pressList } = data;
  let pressMentionsFromWpOption: Queries.WpUpOption_Pressmentions_pressSingle[] = [];
  if (pressList && Array.isArray(pressList.pressMentions?.pressSingle)) {
    pressMentionsFromWpOption = pressList.pressMentions.pressSingle;
  }
  const getPressMentions: IPressBlock[] =
    pressMentionsFromWpOption.map((post) => {
      return {
        pressTitle: post?.pressTitle || '',
        pressDate: post?.pressDate || '',
        publicationName: post?.pressPublisher || '',
        link: post?.pressLink || '',
      };
    }) || [];
  let mentionsSorted = getPressMentions.sort(function (a, b) {
    var c = Date.parse(a.pressDate);
    var d = Date.parse(b.pressDate);
    return d - c;
  });
  const handleSetCurrentPageIndex = (pageIndex: number) => {
    sendHtPageView(
      htevents,
      HT_PAGE_CATEGORY_ARCHIVE_PAGE,
      title + ' - Page ' + currentPageIndex,
      location,
      location.pathname,
    );
    setCurrentPageIndex(pageIndex);
  };
  const pressListPerPage = mentionsSorted.slice(
    currentPageIndex * MAX_NUMBER_OF_CARDS_PER_PAGE,
    currentPageIndex * MAX_NUMBER_OF_CARDS_PER_PAGE + MAX_NUMBER_OF_CARDS_PER_PAGE,
  );

  return (
    <HTPageWrapper
      category={HT_PAGE_CATEGORY_ARCHIVE_PAGE}
      title={title + ' - Page ' + currentPageIndex}
      location={location}
    >
      <Press
        pageHeader={{
          title: title,
        }}
        pressList={pressListPerPage}
        pagination={{
          currentPageIndex,
          pageSize: MAX_NUMBER_OF_CARDS_PER_PAGE,
          setCurrentPageIndex: handleSetCurrentPageIndex,
          total: getPressMentions.length,
        }}
      />
    </HTPageWrapper>
  );
};

export const Head = ({ location }: PageProps) => {
  const pageTitle = 'Upgraded Points: Press and Brand Mentions Across the Web - UpgradedPoints.com';
  const socialTitle = 'Upgraded Points: Press and Brand Mentions Across the Web'; //TODO
  const ogDescription =
    'Notable Press & Brand Mentions for Upgraded Points ‘Ridiculous’ airline fees for checked bags and seating assignments could become a thing of the past… Fortune Feb 1, 2023 Arrive extra-early at these 15 U.S. airports—up to 3 hours—if you don’t...';
  const seo = getDefaultSEO(location.pathname, pageTitle, socialTitle, ogDescription, false);
  return <Seo title={pageTitle} wpSeo={seo} />;
};

export default PressMentions;

export const query = graphql`
  query PressMentionsQuery {
    pressList: wpUpOption(slug: { eq: "press-mentions" }) {
      pressMentions {
        pressSingle {
          pressDate
          pressLink
          pressPublisher
          pressTitle
        }
      }
    }
  }
`;
