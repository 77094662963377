import React, { FC } from 'react';
import { renderItems } from '../../utils';
import PageHeader from '../PageHeader';
import { IPress } from './Press.def';
import PaginationV2 from '../PaginationV2';

const Press: FC<IPress> = ({ pageHeader, pressList, pagination }) => {
  return (
    <>
      <PageHeader {...pageHeader} />
      <section className="site-content">
        <div className="main">
          <div className="pressList container">{renderItems(pressList, 'PressBlock')}</div>
          {pagination && typeof pagination.total === 'number' && pagination.total > 0 && (
            <PaginationV2 {...pagination} />
          )}
        </div>
      </section>
    </>
  );
};

export default Press;
